// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("C:\\webroot\\sidcweb\\sidc-coop\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("C:\\webroot\\sidcweb\\sidc-coop\\src\\pages\\about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-board-of-directors-js": () => import("C:\\webroot\\sidcweb\\sidc-coop\\src\\pages\\board-of-directors.js" /* webpackChunkName: "component---src-pages-board-of-directors-js" */),
  "component---src-pages-career-js": () => import("C:\\webroot\\sidcweb\\sidc-coop\\src\\pages\\career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-chairperson-message-js": () => import("C:\\webroot\\sidcweb\\sidc-coop\\src\\pages\\chairperson-message.js" /* webpackChunkName: "component---src-pages-chairperson-message-js" */),
  "component---src-pages-culture-js": () => import("C:\\webroot\\sidcweb\\sidc-coop\\src\\pages\\culture.js" /* webpackChunkName: "component---src-pages-culture-js" */),
  "component---src-pages-data-privacy-js": () => import("C:\\webroot\\sidcweb\\sidc-coop\\src\\pages\\data-privacy.js" /* webpackChunkName: "component---src-pages-data-privacy-js" */),
  "component---src-pages-index-js": () => import("C:\\webroot\\sidcweb\\sidc-coop\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-membership-js": () => import("C:\\webroot\\sidcweb\\sidc-coop\\src\\pages\\membership.js" /* webpackChunkName: "component---src-pages-membership-js" */),
  "component---src-pages-organizational-chart-js": () => import("C:\\webroot\\sidcweb\\sidc-coop\\src\\pages\\organizational-chart.js" /* webpackChunkName: "component---src-pages-organizational-chart-js" */),
  "component---src-pages-terms-and-condition-js": () => import("C:\\webroot\\sidcweb\\sidc-coop\\src\\pages\\terms-and-condition.js" /* webpackChunkName: "component---src-pages-terms-and-condition-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\webroot\\sidcweb\\sidc-coop\\.cache\\data.json")

